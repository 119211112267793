/* eslint-disable import/prefer-default-export */
import { apiClient } from './axiosSettings'
import env_variables from '../../../config/env_variables'
import getAuthHeaders from './getAuthHeaders'
import axios from 'axios'
import { FEATURE_FLAG_AA_Q } from '../Constants'
import Utility from '../Modules/Utility'

type SentimentType = 'NEGATIVE' | 'POSITIVE' | 'NEUTRAL'
type ContactSentimentResponse = {
  cumulativeSentiment: {
    sentiment: SentimentType
    alertState: string
    alertTime?: number
    currentSentiment: Array<{
      sentiment: SentimentType
      timeStamp: number
    }>
  }
  message?: string
}

type ConversationOverallSentimentResponse = {
  conversationSentimentData: {
    initialContactId: string
    listOfAllContactIds: string[]
    overallSentiment: string
    overallConversationSentiment: string
    timestamp: number
    listOfAllContactIdsSentiment: {
      [contactId: string]: {
        sentiment: string
      }
    }[]
  }
}

type ConversationLegsSentimentResponse = {
  sentiment: {
    [contactId: string]: SentimentType
  }
}

export async function fetchTranscripts(contactId: string) {
  try {
    const apiUrl = `${env_variables.CCP_API_END_POINT}/api/transcripts`
    const transcripts = await apiClient.get(`${apiUrl}/${contactId}?mode=short`, { headers: getAuthHeaders() })
    return transcripts
  } catch (e) {
    console.log('error')
  }
}

export async function fetchTranslationTranscripts(reqBody: any) {
  try {
    const translateChat: any = await apiClient.post(`${env_variables.CCP_API_END_POINT}/api/translate/chat`, reqBody, { headers: getAuthHeaders() })
    const text = translateChat?.data?.data?.texts[0]?.text || ''
    return text
  } catch (e) {
    console.log('error')
  }
}

export async function fetchConversationSummary(conversation: Array<any>, params: any, questionsEnabled?: boolean) {
  try {
    const { ldap, path, contactId, queue, bullets, final, language } = params
    const apiUrl = `${
      env_variables.GENAI_MIDDLEWARE_URL
    }/conversation/summary?advanced=true&ContentId=${contactId}&AgentLdap=${ldap}&Queue=${queue}&Language=${language}&TransferCategory=${path}&UseCase=conversation summary&lines=one${
      bullets ? `&bullets=${bullets}` : ''
    }${final ? '&final=true' : ''}${questionsEnabled ? `&${FEATURE_FLAG_AA_Q}=true` : ''}`
    const headers = { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    const response = await axios.post(apiUrl, { transcript: conversation }, { headers })
    return response?.data
  } catch (e) {
    console.log('Error querying conversation summary. Error: ', e)
  }
}
export async function updateConversationSummaryFeedbackAPI(params: any, contactId: any) {
  try {
    const apiUrl = `${env_variables.GENAI_MIDDLEWARE_URL}/feedback/summary?UseCase=conversation summary&Surface=oac-custom-panel&ContentId=${contactId}`

    const headers = { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    const response = await axios.put(apiUrl, params, { headers })
    return response?.data
  } catch (e) {
    console.log('Error querying conversation summary. Error: ', e)
    return {}
  }
}

type FinalSummaryResponse = {
  SequenceId?: string;
  ContentId?: string;
  AgentLdap?: string;
  Summary: {
      main_issue: string;
      conversation_points: string[];
      links: string[];
      final: boolean;
  };
  TransferCategory: string;
  CreatedTimestamp?: string;
  final?: boolean;
  path?: Array<{[key: string]: string}>
  SummaryUseful?: boolean
}

export async function getClosedConversationSummaryAPI(params: { conversationId: string; transcript: any[] }) {
  try {
    const { conversationId, transcript } = params
    const apiUrl = `${env_variables.GENAI_MIDDLEWARE_URL}/feedback/finalsummary?ContentId=${conversationId}`
    const headers = { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    const response = await axios.get<FinalSummaryResponse>(apiUrl, { headers })
    const path = Utility.getPathFromTranscript(transcript)
    const data = response.data
    data.path = path
    return data
  } catch (e) {
    console.log('Error querying conversation summary. Error: ', e)
    throw e
  }
}

function getRTCSAuthorization () {
  if(process.env.REACT_APP_NODE_ENV === 'stage2' || process.env.REACT_APP_NODE_ENV ===  'alpha2') {
    return localStorage.getItem('prodCustomPanelToken').replaceAll('"', '') 
  } else return localStorage.getItem('customPanelAccessToken').replaceAll('"', '') 
}

export async function fetchContactLegSentiments(contactIds: string[], initialContactId: string) {
  try {
    const URL = `${env_variables.ENDPOINT_RTS_PROCESSOR}/sentiment/contact/closed?initialContactId=${initialContactId}`
    const headers = { 'x-api-key': 'oac-custom-panel', Authorization: getRTCSAuthorization() ?? '' }
    const response = await axios.post<ConversationLegsSentimentResponse>(URL, { contactIds }, { headers })

    return response?.data?.sentiment
  } catch (err: any) {
    console.log('Error fetching Conversation Sentiment: ', err?.message ?? 'Internal Error')
    throw err
  }
}

export async function fetchContactRealTimeSentiments(
  contactId: string,
  initialContactId: string,
  minCharacterThreshold: number,
  agentLdap: string,
  queueName: string,
  managerLdap?: string,
  previousContactLegInfo: any[] = [],
  isAgentEligible: boolean = false
): Promise<{
  contactId: string
  sentiment: {
    message: string
    sentiment: string
    alertState: string
    timeFirstNegative: number
    transcriptSentiment: Array<{
      sentiment: string
      timeStamp: number
    }>
  }
}> {
  try {
    const response: { data: ContactSentimentResponse } = await apiClient.post(
      `${env_variables.ENDPOINT_RTS_PROCESSOR}/sentiment/contact/active?contactId=${contactId}&agentLdap=${agentLdap}&minCharacterThreshold=${minCharacterThreshold}&queueName=${queueName}&managerLdap=${managerLdap}&initialContactId=${initialContactId}&isAgentEligible=${isAgentEligible}`,
      { contactIds: previousContactLegInfo },
      {
        headers: {
          'x-api-key': 'oac-custom-panel',
          'Access-Control-Allow-Origin': '*',
          Authorization: getRTCSAuthorization() ?? ''
        }
      }
    )

    return {
      contactId,
      sentiment: {
        message: response?.data?.message,
        sentiment: response?.data?.cumulativeSentiment?.sentiment,
        alertState: response?.data?.cumulativeSentiment?.alertState ?? '',
        timeFirstNegative: response?.data?.cumulativeSentiment?.alertTime ?? 0,
        transcriptSentiment: response?.data?.cumulativeSentiment?.currentSentiment
      }
    }
  } catch (err: any) {
    console.log('Error fetching real time contact sentiment: ', err?.message ?? 'Internal Error')
    throw err
  }
}

type AlertNowReturnType = {
  alertState: string
  alertTime: number
}

export async function alertManagerNegativeSentimentApi(
  contactId: string,
  agentLdap: string,
  managerLdap: string,
  queueName: string,
  initialContactId: string,
  previousLegInfo: string[] = []
): Promise<AlertNowReturnType> {
  try {
    const response = await apiClient.post<AlertNowReturnType>(
      `${env_variables.ENDPOINT_RTS_PROCESSOR}/alert?contactId=${contactId}&agentLdap=${agentLdap}&managerLdap=${managerLdap}&queueName=${queueName}&initialContactId=${initialContactId}`,
      { contactIds: previousLegInfo },
      {
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: getRTCSAuthorization() ?? '' }
      }
    )

    return response.data
  } catch (err) {
    console.log('Error alerting agent manager for negative sentiment')
    throw err
  }
}

export async function fetchConversationOverallSentimentApi(contactId: string): Promise<ConversationOverallSentimentResponse> {
  try {
    const response = await apiClient.get<ConversationOverallSentimentResponse>(`${env_variables.ENDPOINT_RTS_PROCESSOR}/sentiment/conversation?initialContactId=${contactId}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: getRTCSAuthorization() ?? '' }
    })

    return response.data
  } catch (err) {
    console.log('Error fetching the contact sentiment from the backend')
    throw err
  }
}
